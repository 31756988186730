import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                                “We went to the grand opening of this fantastic place! It's wonderful. Clean, very friendly staff, so much fun for kids of all ages. I had the opportunity and pleasure to speak with the owner. She is extremely invested in the gym. She walked me through the layout and different stations. They are specifically designed for sensory play, pretend play and just fun. There is a zip line, climbing wall, trampoline, climbing structures. My 8 year old daughter had an amazing time!”
                            </p>
                            <p className="overview">Christy K</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “Magical facility & perfect for all capabilities and ages!<br />
                                The gym is large, VERY clean and has plenty of space for the kiddos to run and play!<br />
                                My 1.5 year old loved it!”
                            </p>
                            <p className="overview">Jessica T</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “We took my 2.5 year daughter to We Rock the Spectrum yesterday. We were so impressed! The facility is clean, there is fun stuff to do for all ages, and of course the owners and staff are incredibly friendly and make you feel right at home. My daughter especially loved the trampoline and the toddler toy area. Great place to go to get some energy out this winter- we will for sure be back"
                            </p>
                            <p className="overview">
                            Deanna N
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                “We spent an afternoon there and had so much fun! We were there for over 2 hours and my kid (4years old) was not ready to leave. the facility was clean with lots of things to do. We will definitely be back!"
                            </p>
                            <p className="overview">
                            Ashley P
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                “So glad to have something like this close to home, I took my 2 year old and 11 year old boys there today. the 2 year old could of stayed all day (minus being exhausted after 2 hours) my 11 year old thought it was awesome the first 1 hour then slowly got bored. I'd say it's perfect for 1year olds- 8 year olds, however my 11 year old said he'd definitely go back."
                            </p>
                            <p className="overview">
                            Anonymous
                            </p>
                        </div> 
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “We bought a 6 pack so will definitely be back"
                            </p>
                            <p className="overview">
                            Kayla R
                            </p>
                        </div> 
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
